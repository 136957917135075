import axios from "axios";
import config from "./AuthUser";

const API_BASE_URL = config.apiBaseUrl;

  
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
//Setting of dashboard
/*****Company Settings ******/
export const companySettingsApi = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getGeneralSettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching company settings:", error);
      throw error;
    }
  },

  post: async (formData) => {
    try {
      const response = await apiClient.post("/updateCompanySettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },

  AppSettingsUpdate: async (formData) => {
    try {
      const response = await apiClient.post("/AppSettingsUpdate", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },

  PaymentSettingsUpdate: async (formData) => {
    try {
      const response = await apiClient.post("/updateSettingsPayment", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },

};

// Finance Settings

export const financeSettingsApi = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getfinancesettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  updateinvoice: async (formData) => {
    try {
      const response = await apiClient.post("/updateinvoicesettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  updateprefix: async (formData) => {
    try {
      const response = await apiClient.post("/updateprefixsettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },


  getAllInvoicePaymenst: async () => {
    try {
      const response = await apiClient.get("/getallpaymentsettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  addpaymentsettings: async (formData) => {
    try {
      const response = await apiClient.post("/addpaymentsettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  updatepaymentsettings: async (id,formData) => {
    try {
      const response = await apiClient.post(`/updatepaymentsettings/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  deletepaymentsettings: async (id,formData) => {
    try {
      const response = await apiClient.delete(`/deletepaymentsettings/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  getAllCurrency: async () => {
    try {
      const response = await apiClient.get("/getAllCurrency");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  addCurrency: async (formData) => {
    try {
      const response = await apiClient.post("/addCurrency", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },
  updateDefaultCurrency: async (id,formData) => {
    try {
      const response = await apiClient.post(`/updateDefaultCurrency/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },



  deleteDefaultCurrency: async (id,formData) => {
    try {
      const response = await apiClient.delete(`/deleteDefaultCurrency/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

};

// Profile Settings

export const profileSettingsApi = {
  getById: async (empid) => {
    try {
      const response = await apiClient.post(`/getEmployeesbyID/${empid}`); 
      return response.data;
    } catch (error) {
      console.error("Error fetching employee data:", error);
      throw error;
    }
  },
  update: async (empid,formData) => {
    try {
      const response = await apiClient.post(`/updateEmployeedata/${empid}`, formData); 
      return response.data;
    } catch (error) {
      console.error("Error updating employee data:", error);
      throw error;
    }
  },
};


/*****App Settings ******/
export const AppSettingsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleBrands", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleBrands");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleBrandsByID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};
/*****Business Settings ******/
export const BusinessSettingsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addBusinesslocation", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/listBusinessLocations");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },

  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateBusinessLocation/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  edit: async (id) => {
    try {
      const response = await apiClient.get(`/businesslocationget/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
// Vehicle Brands API
export const vehicleBrandsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleBrands", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleBrands");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleBrandsByID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};

// Vehicle  model API
export const VehicleModelsApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleModel", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleModels");
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleModelById/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};
//Department api
export const DepartmentApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addDepartment", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getDepartments");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteDepartment/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateDepartment/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
//Designation api
export const DesignationApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addDesignation", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getDesignation");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteDesignation/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateDesignation/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
//Jobs api
export const JobsApis = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getJobDetails");
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  closebyId: async (id) => {
    try {
      const response = await apiClient.get(`/changestatusofjob/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};

// Ticket APIs
