import Allroutes from "./Routes/Allroutes.jsx";
import SnackbarProvider from "./Context/SnackbarContext.js";

//  const userInfo = localStorage.getItem('user-info');

function App() {
  return (
    <SnackbarProvider> 
      <Allroutes />
    </SnackbarProvider>
  );
}

export default App;
