import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ContactsIcon from "@mui/icons-material/Contacts";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import BadgeIcon from "@mui/icons-material/Badge";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PaymentIcon from "@mui/icons-material/Payment";
import LanIcon from "@mui/icons-material/Lan";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import DraftsIcon from "@mui/icons-material/Drafts";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Groups2Icon from "@mui/icons-material/Groups2";
import BugReportIcon from "@mui/icons-material/BugReport";
import MessageIcon from "@mui/icons-material/Message";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SchoolIcon from "@mui/icons-material/School";
import HelpIcon from "@mui/icons-material/Help";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SettingsIcon from "@mui/icons-material/Settings";
import AttributionIcon from "@mui/icons-material/Attribution";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import BusinessIcon from "@mui/icons-material/Business";
import LogoExpanded from "../../assets/mainlogo.png";
import SidebarIcons from "./SidebarIcons";

function MainSidebar({ showSidebar, isDown, onClose }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  // const [open, setOpen] = useState(false);
  const handleSubMenuClick = (submenu) => {
    submenu === activeSubMenu
      ? setActiveSubMenu(null)
      : setActiveSubMenu(submenu);
  };

  const handleMenuItemClick = (itemName) => {
    setSelected(itemName);
    // if (isCollapsed) {
    //   setIsCollapsed(false);
    //   // Close the sidebar if it's collapsed
    // }
    onClose && onClose();
  };

  const ComponentLists = [
    <SubMenu
      label="DashBoard"
      icon={<DashboardIcon style={{ color: "black" }} />}
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "dashboard"}
      onClick={() => handleSubMenuClick("dashboard")}
    >
      <MenuItem
        component={<Link to="/rptpl" />}
        style={{
          color: selected === "Private DashBoard" ? "white" : "black",
          backgroundColor:
            selected === "Private DashBoard" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Private DashBoard")}
      >
        Private DashBoard
      </MenuItem>

      <MenuItem
        component={<Link to="/admin-dashboard" />}
        style={{
          color: selected === "Admin DashBoard" ? "white" : "black",
          backgroundColor:
            selected === "Admin DashBoard" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Admin DashBoard")}
      >
        Admin DashBoard
      </MenuItem>
    </SubMenu>,
    <SubMenu
      label="Leads"
      icon={<ContactsIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "leads"}
      onClick={() => handleSubMenuClick("leads")}
    >
      <MenuItem
        component={<Link to="/leads/sales" />}
        style={{
          color: selected === "Sales" ? "white" : "black",
          backgroundColor: selected === "Sales" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Sales")}
      >
        Sales
      </MenuItem>

      <MenuItem
        component={<Link to="/leads" />}
        style={{
          color: selected === "Leads" ? "white" : "black",
          backgroundColor: selected === "Leads" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Leads")}
      >
        Leads
      </MenuItem>
    </SubMenu>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "sales_team" ? "white" : "black",
        backgroundColor: selected === "sales_team" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/field_team" />}
      onClick={() => handleMenuItemClick("sales_team")}
    >
      <AttributionIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      OnField Team
    </MenuItem>,

    <SubMenu
      label="Users"
      icon={<PeopleIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "users"}
      onClick={() => handleSubMenuClick("users")}
    >
      <MenuItem
        component={<Link to="/Users/Drivers" />}
        style={{
          color: selected === "drivers" ? "white" : "black",
          backgroundColor: selected === "drivers" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("drivers")}
      >
        Drivers
      </MenuItem>
      <MenuItem
        component={<Link to="/Users/Fleet_Owners" />}
        style={{
          color: selected === "Fleet-Owners" ? "white" : "black",
          backgroundColor:
            selected === "Fleet-Owners" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Fleet-Owners")}
      >
        Fleet Owners
      </MenuItem>

      <MenuItem
        component={<Link to="/Users/Dhaba" />}
        style={{
          color: selected === "Dhaba" ? "white" : "black",
          backgroundColor: selected === "Dhaba" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Dhaba")}
      >
        Dhaba
      </MenuItem>

      <MenuItem
        component={<Link to="/Users/mech_user" />}
        style={{
          color: selected === "Mechanic" ? "white" : "black",
          backgroundColor: selected === "Mechanic" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Mechanic")}
      >
        Mechanic
      </MenuItem>

      <MenuItem
        component={<Link to="/Users/Crane_Owners" />}
        style={{
          color: selected === "Crane-Owners" ? "white" : "black",
          backgroundColor:
            selected === "Crane-Owners" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Crane-Owners")}
      >
        Crane Owners
      </MenuItem>

      <MenuItem
        component={<Link to="/Users/Crane_Ope" />}
        style={{
          color: selected === "Crane" ? "white" : "black",
          backgroundColor: selected === "Crane" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Crane")}
      >
        Crane Operator
      </MenuItem>
    </SubMenu>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "membership" ? "white" : "black",
        backgroundColor: selected === "membership" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/membership" />}
      onClick={() => handleMenuItemClick("membership")}
    >
      <LoyaltyIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Membership
    </MenuItem>,
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "clients" ? "white" : "black",
        backgroundColor: selected === "clients" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/clients" />}
      onClick={() => handleMenuItemClick("clients")}
    >
      <PeopleIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Clients
    </MenuItem>,
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Vehicals_Data" ? "white" : "black",
        backgroundColor:
          selected === "Vehicals_Data" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/Vehicals_Data" />}
      onClick={() => handleMenuItemClick("Vehicals_Data")}
    >
      <DirectionsBusFilledIcon
        style={{ marginRight: "15px", marginBottom: "-5px" }}
      />
      Vehicals Data
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Convoy" ? "white" : "black",
        backgroundColor: selected === "Convoy" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/Convoy" />}
      onClick={() => handleMenuItemClick("Convoy")}
    >
      <AccountTreeIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Convoy
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Crane_booking" ? "white" : "black",
        backgroundColor:
          selected === "Crane_booking" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/Crane_booking" />}
      onClick={() => handleMenuItemClick("Crane_booking")}
    >
      <PrecisionManufacturingIcon
        style={{ marginRight: "15px", marginBottom: "-5px" }}
      />
      Crane Bookings
    </MenuItem>,
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Jobs" ? "white" : "black",
        backgroundColor: selected === "Jobs" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/Jobs" />}
      onClick={() => handleMenuItemClick("Jobs")}
    >
      <LocalShippingIcon
        style={{ marginRight: "15px", marginBottom: "-5px" }}
      />
      Jobs
    </MenuItem>,

    // <SubMenu
    //   label="Jobs"
    //   icon={<LocalShippingIcon style={{ color: "black" }} />}
    //   //iconColor="black"
    //   style={{ display: "flex", alignItems: "center", color: "black" }}
    //   open={activeSubMenu === "jobs"}
    //   onClick={() => handleSubMenuClick("jobs")}
    // >
    //   <MenuItem
    //     component={<Link to="/Jobs/jobs" />}
    //     style={{
    //       color: selected === "Jobs" ? "white" : "black",
    //       backgroundColor: selected === "Jobs" ? "#C0B476" : "transparent",
    //     }}
    //     onClick={() => handleMenuItemClick("Jobs")}
    //   >
    //     Jobs
    //   </MenuItem>
    //   <MenuItem
    //     component={<Link to="/Jobs/applications" />}
    //     style={{
    //       color: selected === "Applications" ? "white" : "black",
    //       backgroundColor:
    //         selected === "Applications" ? "#C0B476" : "transparent",
    //     }}
    //     onClick={() => handleMenuItemClick("Applications")}
    //   >
    //     Job Applications
    //   </MenuItem>
    // </SubMenu>,

    <SubMenu
      label="HR"
      icon={<BadgeIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "hr"}
      onClick={() => handleSubMenuClick("hr")}
    >
      <MenuItem
        component={<Link to="/hr/employees" />}
        style={{
          color: selected === "employees" ? "white" : "black",
          backgroundColor: selected === "employees" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("employees")}
      >
        Employees
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/leaves" />}
        style={{
          color: selected === "leaves" ? "white" : "black",
          backgroundColor: selected === "leaves" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("leaves")}
      >
        Leaves
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/shift" />}
        style={{
          color: selected === "shift" ? "white" : "black",
          backgroundColor: selected === "shift" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("shift")}
      >
        Shift Roster
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/attendance" />}
        style={{
          color: selected === "attendance" ? "white" : "black",
          backgroundColor:
            selected === "attendance" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("attendance")}
      >
        Attendance
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/holiday" />}
        style={{
          color: selected === "holiday" ? "white" : "black",
          backgroundColor: selected === "holiday" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("holiday")}
      >
        Holiday
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/designation" />}
        style={{
          color: selected === "designation" ? "white" : "black",
          backgroundColor:
            selected === "designation" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("designation")}
      >
        Designation
      </MenuItem>
      <MenuItem
        component={<Link to="/hr/department" />}
        style={{
          color: selected === "department" ? "white" : "black",
          backgroundColor:
            selected === "department" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("department")}
      >
        Department
      </MenuItem>

      <MenuItem
        component={<Link to="/hr/appreciation" />}
        style={{
          color: selected === "appreciation" ? "white" : "black",
          backgroundColor:
            selected === "appreciation" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("appreciation")}
      >
        Appreciation
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Payroll"
      icon={<PaymentIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "payroll"}
      onClick={() => handleSubMenuClick("payroll")}
    >
      <MenuItem
        component={<Link to="/payroll/staff" />}
        style={{
          color: selected === "staff" ? "white" : "black",
          backgroundColor: selected === "staff" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("staff")}
      >
        Staff Salary
      </MenuItem>
      <MenuItem
        component={<Link to="/payroll/payroll" />}
        style={{
          color: selected === "payroll" ? "white" : "black",
          backgroundColor: selected === "payroll" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("payroll")}
      >
        Payroll
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Work"
      icon={<LanIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "work"}
      onClick={() => handleSubMenuClick("work")}
    >
      <MenuItem
        component={<Link to="/work/contracts" />}
        style={{
          color: selected === "contracts" ? "white" : "black",
          backgroundColor: selected === "contracts" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("contracts")}
      >
        Contracts
      </MenuItem>
      <MenuItem
        component={<Link to="/work/projects" />}
        style={{
          color: selected === "projects" ? "white" : "black",
          backgroundColor: selected === "projects" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("projects")}
      >
        Projects
      </MenuItem>

      <MenuItem
        component={<Link to="/work/tasks" />}
        style={{
          color: selected === "tasks" ? "white" : "black",
          backgroundColor: selected === "tasks" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("tasks")}
      >
        Tasks
      </MenuItem>

      <MenuItem
        component={<Link to="/work/time_Sheet" />}
        style={{
          color: selected === "time_Sheet" ? "white" : "black",
          backgroundColor:
            selected === "time_Sheet" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("time_Sheet")}
      >
        Time Sheet
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Finance"
      icon={<CurrencyRupeeIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "finance"}
      onClick={() => handleSubMenuClick("finance")}
    >
      <MenuItem
        component={<Link to="/finance/proposal" />}
        style={{
          color: selected === "proposal" ? "white" : "black",
          backgroundColor: selected === "proposal" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("proposal")}
      >
        Proposal
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/estimate" />}
        style={{
          color: selected === "estimate" ? "white" : "black",
          backgroundColor: selected === "estimate" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("estimate")}
      >
        Estimates
      </MenuItem>

      <MenuItem
        component={<Link to="/finance/invoice" />}
        style={{
          color: selected === "invoice" ? "white" : "black",
          backgroundColor: selected === "invoice" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("invoice")}
      >
        Invoice
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/payments" />}
        style={{
          color: selected === "payments" ? "white" : "black",
          backgroundColor: selected === "payments" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("payments")}
      >
        Payments
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/credit" />}
        style={{
          color: selected === "credit" ? "white" : "black",
          backgroundColor: selected === "credit" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("credit")}
      >
        Credit Note
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/expenses" />}
        style={{
          color: selected === "expenses" ? "white" : "black",
          backgroundColor: selected === "expenses" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("expenses")}
      >
        Expenses
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/bank" />}
        style={{
          color: selected === "bank" ? "white" : "black",
          backgroundColor: selected === "bank" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("bank")}
      >
        Bank Details
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/settlements" />}
        style={{
          color: selected === "settlements" ? "white" : "black",
          backgroundColor:
            selected === "settlements" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("settlements")}
      >
        Settlements
      </MenuItem>
      <MenuItem
        component={<Link to="/finance/Wallet" />}
        style={{
          color: selected === "Wallet" ? "white" : "black",
          backgroundColor: selected === "Wallet" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Wallet")}
      >
        Wallet
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Assets"
      icon={<WebAssetIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "assets"}
      onClick={() => handleSubMenuClick("assets")}
    >
      <MenuItem
        component={<Link to="/assets" />}
        style={{
          color: selected === "assets" ? "white" : "black",
          backgroundColor: selected === "assets" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("assets")}
      >
        Assets
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/products" />}
        style={{
          color: selected === "products" ? "white" : "black",
          backgroundColor: selected === "products" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("products")}
      >
        Products
      </MenuItem>

      <MenuItem
        component={<Link to="/assets/orders" />}
        style={{
          color: selected === "orders" ? "white" : "black",
          backgroundColor: selected === "orders" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("orders")}
      >
        Orders
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/vendor" />}
        style={{
          color: selected === "vendor" ? "white" : "black",
          backgroundColor: selected === "vendor" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("vendor")}
      >
        Vendor
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/purchase_order" />}
        style={{
          color: selected === "purchase_order" ? "white" : "black",
          backgroundColor:
            selected === "purchase_order" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("purchase_order")}
      >
        Purchase Order
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/bills" />}
        style={{
          color: selected === "bills" ? "white" : "black",
          backgroundColor: selected === "bills" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("bills")}
      >
        Bills
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/vendor_payments" />}
        style={{
          color: selected === "vendor_payments" ? "white" : "black",
          backgroundColor:
            selected === "vendor_payments" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("vendor_payments")}
      >
        Vendor Payments
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/vendor_credits" />}
        style={{
          color: selected === "vendor_credits" ? "white" : "black",
          backgroundColor:
            selected === "vendor_credits" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("vendor_credits")}
      >
        Vendor Credits
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/Inventory" />}
        style={{
          color: selected === "Inventory" ? "white" : "black",
          backgroundColor: selected === "Inventory" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Inventory")}
      >
        Inventory
      </MenuItem>
      <MenuItem
        component={<Link to="/assets/Report" />}
        style={{
          color: selected === "Report" ? "white" : "black",
          backgroundColor: selected === "Report" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Report")}
      >
        Reports
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Letter"
      icon={<DraftsIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "letter"}
      onClick={() => handleSubMenuClick("letter")}
    >
      <MenuItem
        component={<Link to="/letter/generate" />}
        style={{
          color: selected === "generate" ? "white" : "black",
          backgroundColor: selected === "generate" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("generate")}
      >
        Generate
      </MenuItem>
      <MenuItem
        component={<Link to="/letter/templete" />}
        style={{
          color: selected === "templete" ? "white" : "black",
          backgroundColor: selected === "templete" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("templete")}
      >
        Templetes
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Recruit"
      icon={<WorkIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "recruit"}
      onClick={() => handleSubMenuClick("recruit")}
    >
      <MenuItem
        component={<Link to="/recruit/dashboard" />}
        style={{
          color: selected === "rdashboard" ? "white" : "black",
          backgroundColor:
            selected === "rdashboard" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("rdashboard")}
      >
        DashBoard
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/skills" />}
        style={{
          color: selected === "skills" ? "white" : "black",
          backgroundColor: selected === "skills" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("skills")}
      >
        Skills
      </MenuItem>

      <MenuItem
        component={<Link to="/recruit/jobs" />}
        style={{
          color: selected === "jobs" ? "white" : "black",
          backgroundColor: selected === "jobs" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("jobs")}
      >
        Jobs
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/applications" />}
        style={{
          color: selected === "applications" ? "white" : "black",
          backgroundColor:
            selected === "applications" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("applications")}
      >
        Applications
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/interview" />}
        style={{
          color: selected === "interview" ? "white" : "black",
          backgroundColor: selected === "interview" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("interview")}
      >
        Interview Schedule
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/site" />}
        style={{
          color: selected === "site" ? "white" : "black",
          backgroundColor: selected === "site" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("site")}
      >
        Career Site
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/candDb" />}
        style={{
          color: selected === "candidates" ? "white" : "black",
          backgroundColor:
            selected === "candidates" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("candidates")}
      >
        Candidates Database
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/offer" />}
        style={{
          color: selected === "offer" ? "white" : "black",
          backgroundColor: selected === "offer" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("offer")}
      >
        Offer Letter
      </MenuItem>
      <MenuItem
        component={<Link to="/recruit/reports" />}
        style={{
          color: selected === "oreports" ? "white" : "black",
          backgroundColor: selected === "oreports" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("oreports")}
      >
        Reports
      </MenuItem>
    </SubMenu>,

    <SubMenu
      label="Meetings"
      icon={<Groups2Icon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "meetings"}
      onClick={() => handleSubMenuClick("meetings")}
    >
      <MenuItem
        component={<Link to="/meetings/zoom" />}
        style={{
          color: selected === "zoom" ? "white" : "black",
          backgroundColor: selected === "zoom" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("zoom")}
      >
        Zoom
      </MenuItem>
      <MenuItem
        component={<Link to="/meetings/teams" />}
        style={{
          color: selected === "Teams" ? "white" : "black",
          backgroundColor: selected === "Teams" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("Teams")}
      >
        Teams
      </MenuItem>
      <MenuItem
        component={<Link to="/meetings/jitsi" />}
        style={{
          color: selected === "jitsi" ? "white" : "black",
          backgroundColor: selected === "jitsi" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("jitsi")}
      >
        Jitsi
      </MenuItem>
    </SubMenu>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "tickets" ? "white" : "black",
        backgroundColor: selected === "tickets" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/tickets" />}
      onClick={() => handleMenuItemClick("tickets")}
    >
      <BugReportIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Tickets
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Calendar" ? "white" : "black",
        backgroundColor: selected === "Calendar" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/events" />}
      onClick={() => handleMenuItemClick("Calendar")}
    >
      <CelebrationIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Events
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "message" ? "white" : "black",
        backgroundColor: selected === "message" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/message" />}
      onClick={() => handleMenuItemClick("message")}
    >
      <MessageIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Message
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "notice" ? "white" : "black",
        backgroundColor: selected === "notice" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/notice" />}
      onClick={() => handleMenuItemClick("notice")}
    >
      <EventNoteIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Notice Board
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "knowledge" ? "white" : "black",
        backgroundColor: selected === "knowledge" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/knowledge" />}
      onClick={() => handleMenuItemClick("knowledge")}
    >
      <SchoolIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Knowledge Base
    </MenuItem>,

    <SubMenu
      label="Reports"
      icon={<AssessmentIcon style={{ color: "black" }} />}
      //iconColor="black"
      style={{ display: "flex", alignItems: "center", color: "black" }}
      open={activeSubMenu === "reports"}
      onClick={() => handleSubMenuClick("reports")}
    >
      <MenuItem
        component={<Link to="/report/task" />}
        style={{
          color: selected === "rtask" ? "white" : "black",
          backgroundColor: selected === "rtask" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("rtask")}
      >
        Task Report
      </MenuItem>
      <MenuItem
        component={<Link to="/report/time_log" />}
        style={{
          color: selected === "reporttime_log" ? "white" : "black",
          backgroundColor:
            selected === "reporttime_log" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("reporttime_log")}
      >
        Time Log Report
      </MenuItem>

      <MenuItem
        component={<Link to="/report/finance" />}
        style={{
          color: selected === "reportfinance" ? "white" : "black",
          backgroundColor:
            selected === "reportfinance" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("reportfinance")}
      >
        Finance Report
      </MenuItem>
      <MenuItem
        component={<Link to="/report/income" />}
        style={{
          color: selected === "income" ? "white" : "black",
          backgroundColor: selected === "income" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("income")}
      >
        Income Vs Expense
      </MenuItem>
      <MenuItem
        component={<Link to="/report/leave" />}
        style={{
          color: selected === "reportleave" ? "white" : "black",
          backgroundColor:
            selected === "reportleave" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("reportleave")}
      >
        Leave Report
      </MenuItem>
      <MenuItem
        component={<Link to="/report/deal" />}
        style={{
          color: selected === "deal" ? "white" : "black",
          backgroundColor: selected === "deal" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("deal")}
      >
        Deal Report
      </MenuItem>
      <MenuItem
        component={<Link to="/report/sales" />}
        style={{
          color: selected === "sales" ? "white" : "black",
          backgroundColor: selected === "sales" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("sales")}
      >
        Sales Report
      </MenuItem>
      <MenuItem
        component={<Link to="/report/attendance" />}
        style={{
          color: selected === "reportattendance" ? "white" : "black",
          backgroundColor:
            selected === "reportattendance" ? "#C0B476" : "transparent",
        }}
        onClick={() => handleMenuItemClick("reportattendance")}
      >
        Attendance Report
      </MenuItem>
    </SubMenu>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "help" ? "white" : "black",
        backgroundColor: selected === "help" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/help" />}
      onClick={() => handleMenuItemClick("help")}
    >
      <HelpIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Help
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "export" ? "white" : "black",
        backgroundColor: selected === "export" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/export" />}
      onClick={() => handleMenuItemClick("export")}
    >
      <FileUploadIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Export
    </MenuItem>,

    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        color: selected === "Settings" ? "white" : "black",
        backgroundColor: selected === "Settings" ? "#C0B476" : "transparent",
        marginLeft: "10px",
      }}
      component={<Link to="/settings" />}
      onClick={() => handleMenuItemClick("Settings")}
    >
      <SettingsIcon style={{ marginRight: "15px", marginBottom: "-5px" }} />
      Settings
    </MenuItem>,
  ];
  const [openIndex, setOpenIndex] = useState(0);
  return (
    <Box
      sx={{
        overflowX: "hidden",
        "& .pro-sidebar-inner": {
          background: `#ffffff !important`,
        },
        "&.pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 5px 5px 5px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#EFF5A3 !important",
        },
        "&.pro-menu-item.active": {
          backgroundColor: "#C0B476 !important", // Background color for active MenuItem
          color: "#F9E747 !important",
        },
      }}
    >
      <Sidebar style={{ width: "15%" }}>
        <Menu>
          <MenuItem
            // icon={<MenuOutlinedIcon />}
            style={{
              margin: "10px 0 10px 0",
              color: "black",

              "&:hover": {
                backgroundColor: "#C0B476",
              },
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={LogoExpanded}
                alt="Expanded Logo"
                style={{ width: "140px", height: "auto" }}
              />
              {/* {!showSidebar && <MenuOutlinedIcon />} */}
              {/* <MenuOutlinedIcon /> */}
            </Box>
          </MenuItem>
          {/* paddingLeft={isCollapsed ? undefined : "10%"} */}
          <Box>
            {ComponentLists.map((item, index) => (
              <SidebarIcons
                body={item}
                key={index}
                open={index === openIndex}
                setOpen={() => setOpenIndex(index)}
              />
            ))}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
}

export default MainSidebar;
