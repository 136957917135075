import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Popover,
  Stack,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MUIDataTable from "mui-datatables";

import PaletteIcon from "@mui/icons-material/Palette";

import { ChromePicker } from "react-color";
import EditNoteIcon from "@mui/icons-material/EditNote";
import config from "../../Data/AuthUser";
import { makeStyles } from "@mui/styles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function VehicleModel() {
  const user = JSON.parse(localStorage.getItem("user-info"));
  const [open, setOpen] = React.useState(false);
  const [fuelType, setFuelType] = React.useState(false);

  const [brandname, setBrandname] = useState([]);
  const [vehicalModel, setVehicalModel] = useState([]);
  // const [data,SetData] = useState([]);
  const [color, setColor] = useState("#ff0000");
  const [update, setUpdate] = React.useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleChange = (event) => {
    setColor(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImageUrl(null);
      alert("Please select an image file.");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const SaveDate = async (event) => {
    const last_update = new Date().getTime();
    event.preventDefault();
    if (!selectedFile || !brandname) {
      // showAlert("Please fill all fields and select an image.", "warning");
      // return;
    }

    const formData = new FormData();
    formData.append("brand", brandname);
    formData.append("logo", selectedFile);
    // formData.append("createby", getUserInfoProperty("empid"));
    formData.append("last_update", last_update.toString());

    // try {
    //    const response = await vehicleBrandsApi.add(formData);
    //    console.log(response, "vehical response");

    //   if (response === "1601") {
    //     showAlert("Vehicle brand already exists", "warning");
    //   } else if (response === "1602") {
    //     showAlert("Vehicle brand added successfully", "success");
    //     getData();
    //     setBrandname("");
    //     setSelectedFile(null);
    //     setImageUrl(null);
    //     setOpen(false);
    //   }
    // } catch (error) {
    //   console.error("There was an error!", error);
    //   showAlert("Error uploading vehicle brand.", "warning");
    // }
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setSelectedFile(file);
  //     convertToBase64(file);
  //   }
  // };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to base64:", error);
    };
  };
  //  Image Selections
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [imageUrl, setImageUrl] = useState(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   // Check if a file is selected and it's an image
  //   if (file && file.type.startsWith("image/")) {
  //     setSelectedFile(file);

  //     // Read and display the selected image
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setImageUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file); // Read file as a data URL
  //   } else {
  //     setSelectedFile(null);
  //     setImageUrl(null);
  //     alert("Please select an image file.");
  //   }
  // };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getVehicleModels"
    );
    const response = await results.json();

    // Map through response to convert notice_to values
    // const updatedResponse = response.map((notice) => ({
    //   ...notice,
    //   notice_to: convertToUserType(notice.notice_to),
    //   user_type: convertToMainUserType(notice.user_type), // convert the value
    // }));

    setVehicalModel(response); // Store the updated response
  }
  const openColor = Boolean(anchorEl);
  const id = openColor ? "color-picker-popover" : undefined;
  const handleUpload = () => {
    // Logic to handle image upload
    if (selectedFile) {
      console.log("Selected File:", selectedFile);
      // Implement your image upload logic here
      // For example, you can use FormData to send the image file to a server
    } else {
      console.log("No file selected");
    }
  };
  const handleSaveToServer = async () => {
    if (!base64Image) {
      alert("No image to save.");
      return;
    }

    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/addVehicleModel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ image: base64Image }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save image");
      }

      alert("Image saved to server!");
    } catch (error) {
      console.error("Error saving image to server:", error);
      alert("Error saving image to server.");
    }
  };
  const handleFetchFromServer = async () => {
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getVehicleModels"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const data = await response.json();
      setBase64Image(data.image);
      alert("Image fetched from server!");
    } catch (error) {
      console.error("Error fetching image from server:", error);
      alert("Error fetching image from server.");
    }
  };
  // async function SaveDate() {
  //   const formData = new FormData();
  //   const timestamp = new Date().getTime();
  //   const date = new Date(timestamp);
  //   const last_update = date.toLocaleDateString();
  //   const creator = localStorage.getItem("empid");
  //   formData.append("brand", brandname);
  //   formData.append("brand", brandname);
  //   formData.append("logo", base64Image);
  //   formData.append("createby", creator);
  //   formData.append("last_update", last_update.toString());

  //   try {
  //     let response = await fetch(
  //       "https://rptpl.roadpilot.co.in/public/api/addVehicleModel",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to update data");
  //     }

  //     console.log(response);
  //     alert("Updated");

  //     setOpen(false);
  //     // getData(); // Assuming these functions are defined elsewhere in your code
  //   } catch (error) {
  //     console.error(error);
  //     alert("Failed to update data");
  //   }
  // }
  const useStyles = makeStyles({
    logoImage: {
      width: 80,
      height: 50,
      // borderRadius: "50%",
      objectFit: "cover",
    },
  });

  const LogoRenderer = ({ src }) => {
    const classes = useStyles();
    return <img src={src} alt="Logo" className={classes.logoImage} />;
  };
  const CNG = "CNG";
  const Petrol = "Petrol";
  const Desiel = "Desiel";
  const columns = [
    {
      name: "engine",
      label: "Engine",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Engine
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LogoRenderer src={config.apiImageUrl + value} />
            </div>
          );
        },
      },
    },
    {
      name: "model",
      label: "Model",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Model
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "wheels",
      label: "Wheels",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Wheels
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "fuel",
      label: "Fuel",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Fuel
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value === null ? "Diesel" : value}
            </div>
          );
        },
      },
    },
    {
      name: "id", // Assuming this is the name of the column containing the ID
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
              paddingRight: "20px", // Add padding for starting point
            }}
          >
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                onClick={() => handleEdit(value)}
                sx={{
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                {" "}
                <EditNoteIcon /> Edit
              </Button>
            </Stack>
          </div>
        ),
      },
    },
  ];

  async function handleEdit(id) {}

  const options = {
    selectableRows: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <List style={{ background: "none" }}>
        <ListItemText>
          <React.Fragment>
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "170px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <AddCircleIcon /> Add Vehicle Model
            </Box>

            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    New Vehicle Model
                  </Typography>
                </Toolbar>
              </AppBar>

              <Box sx={{ backgroundColor: "white" }}>
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", padding: "20px" }}
                >
                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Vehical Brand Id
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <Select
                            labelId="department-select-label"
                            id="department-select"
                            value={fuelType}
                            onChange={(e) => setFuelType(e.target.value)}
                            displayEmpty
                            sx={{
                              border: "1px solid black",
                              boxSizing: "border-box",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              color: "black",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>---</em>
                            </MenuItem>
                            {/* {.map((department) => (
                              <MenuItem
                                key={department.id}
                                value={department.department}
                              >
                                {department.department}
                              </MenuItem>
                            ))} */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Color Picker
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          value={color}
                          onClick={handleClick}
                          InputProps={{
                            endAdornment: (
                              <IconButton>
                                <PaletteIcon />
                              </IconButton>
                            ),
                          }}
                          style={{ width: "200px", backgroundColor: color }}
                        />
                        <Popover
                          id={id}
                          open={openColor}
                          anchorEl={anchorEl}
                          onClose={handleCloseAnchor}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <ChromePicker
                            color={color}
                            onChangeComplete={handleColorChange}
                          />
                        </Popover>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", padding: "20px" }}
                >
                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Wheels
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          placeholder="eg. TATA"
                          type="text"
                          inputProps={{ style: { color: "black" } }}
                          onChange={(e) => setBrandname(e.target.value)}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Logo
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            type: "file",
                            accept: "image/*",
                            onChange: handleFileChange,
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                        />
                        {imageUrl && (
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={imageUrl}
                              alt="Selected"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 4,
                    }}
                  >
                    <Typography variant="h4" gutterBottom>
                      Image Upload & Base64 Encoding
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCamera />}
                      sx={{ mb: 2 }}
                    >
                      Upload Image
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                    {base64Image && (
                      <Box
                        component="img"
                        sx={{
                          height: 200,
                          width: 200,
                          mb: 2,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: 3,
                        }}
                        alt="Uploaded"
                        src={base64Image}
                      />
                    )}
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Button variant="outlined" onClick={handleSaveToServer}>
                        Save Image
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleFetchFromServer}
                      >
                        Fetch Image
                      </Button>
                    </Box>
                    <TextField
                      label="Base64 Image String"
                      multiline
                      maxRows={4}
                      value={base64Image}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box> */}
                  {/* <Grid item xs={12} sm={6}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Engine
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          // label="Upload Image"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            type: "file",
                            accept: "image/*",
                            onChange: handleFileChange,
                          }}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                        {imageUrl && (
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={imageUrl}
                              alt="Selected"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                            <div style={{ marginTop: "5px", fontSize: "14px" }}>
                              {selectedFile}
                            </div>
                            <Button
                              variant="outlined"
                              color="error"
                              // onClick={handleRemoveImage} // Handle image removal
                              sx={{ marginTop: "10px" }}
                            >
                              Remove Image
                            </Button>
                          </div>
                        )}
                        {/* {selectedFile && (
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "10px" }}
                          >
                            {selectedFile.map((file, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={file.dataUrl}
                                    alt={file.name}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "200px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.name}
                                  </div>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleRemoveImage(index)}
                                    sx={{ marginTop: "10px" }}
                                  >
                                    Remove Image
                                  </Button>
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        )} */}
                  {/* </Grid>
                    </Grid>
                  </Grid>  */}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", padding: "20px" }}
                >
                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Model
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          placeholder="eg. TATA"
                          type="text"
                          inputProps={{ style: { color: "black" } }}
                          onChange={(e) => setBrandname(e.target.value)}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={11} sm={5.5} md={5.5}>
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        gutterBottom
                        style={{ color: "black" }}
                      >
                        Fuel
                      </Typography>
                      <Select
                        value={fuelType}
                        autoFocus
                        onChange={(e) => setFuelType(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        <MenuItem value={CNG}>CNG</MenuItem>
                        <MenuItem value={Petrol}>Petrol</MenuItem>
                        <MenuItem value={Desiel}>Desiel</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Grid item xs={11} className="messageFooter">
                    <Box className="response" onClick={SaveDate}>
                      Save
                    </Box>
                    <Box className="cancel" onClick={handleClose}>
                      Cancel
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Vehicle Model Information
                </span>
              }
              data={vehicalModel}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default VehicleModel;
