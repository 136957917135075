import { useContext } from "react";

import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MessageIcon from "@mui/icons-material/Message";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuIcon from "@mui/icons-material/Menu";

const LocationPath = ({ location }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const homeLinkStyle = {
    color:
      colorMode === "dark" ? colors.greenAccent[900] : colors.greenAccent[100], // Change color based on color mode
    fontWeight: "bold",
    textDecoration: "none",
  };

  const iconStyle = {
    fontSize: "small",
    verticalAlign: "bottom",
    marginBottom: "4px",
    color:
      colorMode === "dark" ? colors.greenAccent[900] : colors.greenAccent[50], // Change color based on color mode
  };
  const getPageName = (location) => {
    switch (location.pathname) {
      case "/rptpl":
        return (
          <Link to="/rptpl" style={homeLinkStyle}>
            Home
          </Link>
        );
      case "/admin-dashboard":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> admin Dashboard
          </span>
        );
      case "/leads/sales":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Leads{" "}
            <FiberManualRecordIcon style={iconStyle} /> Sales
          </span>
        );
      case "/leads":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Leads
          </span>
        );
      case "/Users/Drivers":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Drivers
          </span>
        );
      case "/Users/Fleet_Owners":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Fleet Owners
          </span>
        );
      case "/Users/Dhaba":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Dhaba
          </span>
        );
      case "/Users/Mechanic":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Mechanic
          </span>
        );
      case "/Users/Crane_Owners":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Crane Owner
          </span>
        );
      case "/Users/Crane_Ope":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Users{" "}
            <FiberManualRecordIcon style={iconStyle} /> Crane Operators
          </span>
        );
      case "/Jobs/jobs":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Jobs{" "}
            <FiberManualRecordIcon style={iconStyle} /> Jobs
          </span>
        );

      case "/Jobs/applications":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Jobs{" "}
            <FiberManualRecordIcon style={iconStyle} /> Applicaions
          </span>
        );
      case "/hr/employees":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Employees
          </span>
        );

      case "/hr/leaves":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Leaves
          </span>
        );
      case "/hr/shift":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Shift Roster
          </span>
        );

      case "/hr/attendance":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Attendance
          </span>
        );
      case "/hr/holiday":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Holiday
          </span>
        );

      case "/hr/designation":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Designation
          </span>
        );
      case "/hr/department":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Department
          </span>
        );

      case "/hr/appreciation":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> HR{" "}
            <FiberManualRecordIcon style={iconStyle} /> Appreciation
          </span>
        );
      case "/payroll/staff":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Payroll{" "}
            <FiberManualRecordIcon style={iconStyle} /> Staff
          </span>
        );
      case "/payroll/report":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Payroll{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report
          </span>
        );

      case "/work/contracts":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Work{" "}
            <FiberManualRecordIcon style={iconStyle} /> Contract
          </span>
        );
      case "/work/projects":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Work{" "}
            <FiberManualRecordIcon style={iconStyle} /> Projects
          </span>
        );
      case "/work/tasks":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Work{" "}
            <FiberManualRecordIcon style={iconStyle} /> Tasks
          </span>
        );
      case "/work/time_Sheet":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Work{" "}
            <FiberManualRecordIcon style={iconStyle} /> Time Sheet
          </span>
        );

      case "/finance/proposal":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Proposal
          </span>
        );
      case "/finance/estimate":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Estimate
          </span>
        );
      case "/finance/invoice":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Invoice
          </span>
        );
      case "/finance/payments":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Payments
          </span>
        );
      case "/finance/credit":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Credit Note
          </span>
        );
      case "/finance/expenses":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Expenses
          </span>
        );
      case "/finance/bank":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Bank Details
          </span>
        );
      case "/finance/settlements":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Settlements
          </span>
        );
      case "/finance/Wallet":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance{" "}
            <FiberManualRecordIcon style={iconStyle} /> Wallet
          </span>
        );

      case "/assets":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets
          </span>
        );
      case "/assets/products":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Products
          </span>
        );

      case "/assets/orders":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Prders
          </span>
        );

      case "/assets/vendor":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Vendor
          </span>
        );

      case "/assets/purchase_order":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Purchase Order
          </span>
        );

      case "/assets/bills":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Bills
          </span>
        );

      case "/assets/vendor_payments":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Vendor Payments
          </span>
        );

      case "/assets/vendor_credits":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Vendor Credits
          </span>
        );

      case "/assets/Inventory":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Inventory
          </span>
        );
      case "/assets/Report":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Assets{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report
          </span>
        );

      case "/letter/generate":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Letter{" "}
            <FiberManualRecordIcon style={iconStyle} /> Generate
          </span>
        );
      case "/letter/templete":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Letter{" "}
            <FiberManualRecordIcon style={iconStyle} /> Templete
          </span>
        );

      case "/recruit/dashboard":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Dashboard
          </span>
        );
      case "/recruit/skills":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Skills
          </span>
        );
      case "/recruit/jobs":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Jobs
          </span>
        );
      case "/recruit/applications":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Applications
          </span>
        );
      case "/recruit/interview":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Interview Schedule
          </span>
        );
      case "/recruit/site":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Career Site
          </span>
        );
      case "/recruit/candidates":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Candidates Database
          </span>
        );
      case "/recruit/offer":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Offer Letter
          </span>
        );
      case "/recruit/reports":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> recruit
            <FiberManualRecordIcon style={iconStyle} /> Reports
          </span>
        );

      case "/mettings/zoom":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Mettings{" "}
            <FiberManualRecordIcon style={iconStyle} />
            Zoom
          </span>
        );
      case "/mettings/Teams":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Mettings{" "}
            <FiberManualRecordIcon style={iconStyle} />
            Teams
          </span>
        );

      case "/mettings/jitsi":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Mettings{" "}
            <FiberManualRecordIcon style={iconStyle} />
            Jitsi
          </span>
        );

      case "/tickets":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Tickets
          </span>
        );
      case "/events":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Events
          </span>
        );

      case "/message":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> message
          </span>
        );
      case "/notice":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Notice Board
          </span>
        );

      case "/knowledge":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Knowledge Base
          </span>
        );

      case "/report/task":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Task Report
          </span>
        );
      case "/report/time_log":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Time Log Report
          </span>
        );

      case "/report/finance":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Finance Report
          </span>
        );

      case "/report/income":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Income Vs Expense
          </span>
        );

      case "/report/leave":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Leave Report
          </span>
        );

      case "/report/deal":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Deal Report
          </span>
        );

      case "/report/sales":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Sales Report
          </span>
        );

      case "/report/others":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Report{" "}
            <FiberManualRecordIcon style={iconStyle} /> Others Report
          </span>
        );

      case "/help":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Help
          </span>
        );

      case "/export":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Export
          </span>
        );
      case "/settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} /> Settings
          </span>
        );
      case "/Business_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Business Settings
          </span>
        );
      case "/App_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> App Settings
          </span>
        );
      case "/Profile_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Profile Settings
          </span>
        );
      case "/tax_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Tax Settings
          </span>
        );
      case "/project_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Project Settings
          </span>
        );
      case "/rp_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Roles and Permission
          </span>
        );
      case "/Ticket_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Tickets Settings
          </span>
        );
      case "/Attendance_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Attendance Settings
          </span>
        );
      case "/Leads_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Leads Settings
          </span>
        );
      case "/Payroll_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Payroll Settings
          </span>
        );
      case "/Leave_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Leave Settings
          </span>
        );
      case "/time_log_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Time Log Settings
          </span>
        );
      case "/Task_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Task Settings
          </span>
        );
      case "/security_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Security Settings
          </span>
        );
      case "/purchase_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Purchase Settings
          </span>
        );
      case "/assets_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Assets Settings
          </span>
        );
      case "/recruit_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Recruit Settings
          </span>
        );
      case "/subscriptoin_settings":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>{" "}
            <FiberManualRecordIcon style={iconStyle} />
            <Link to="/settings" style={homeLinkStyle}>
              Settings
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Subscription Settings
          </span>
        );
      case "/field_team":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> OnField Team
          </span>
        );
      case "/Convoy":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Convoy
          </span>
        );
      case "/Crane_booking":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Crane Bookings
          </span>
        );

      case "/App_Posts":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> App Posts
          </span>
        );
      case "/Vehicals_Data":
        return (
          <span>
            <Link to="/rptpl" style={homeLinkStyle}>
              Home
            </Link>
            <FiberManualRecordIcon style={iconStyle} /> Vehicals Data
          </span>
        );
      default:
        return "";
    }
  };
  return (
    <Box display="flex" variant="h1" fontWeight="bold" sx={{ mb: "5px" }}>
      <Typography>{getPageName(location)}</Typography>
    </Box>
  );
};

export default LocationPath;
