import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";

import RememberMeIcon from "@mui/icons-material/RememberMe";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";

import StatusAlert from "../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
import ClientsHeader from "./ClientsHeader";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Clients() {
  const [name, setName] = React.useState("");
  const [radioButton, setRadioButton] = useState(false);

  const [uploadFile, setUploadFile] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [empid, setEmpid] = React.useState(false);

  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [designation, setDesignation] = React.useState(false);

  const [mobile, setMobile] = React.useState(false);
  const [country, setCountry] = React.useState(false);

  const [language, setLanguage] = React.useState(false);

  const [address, setAddress] = React.useState(false);
  const [about, setAbout] = React.useState(false);

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [articalData, setArticalData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Client");
  const [data, setData] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateEdit = () => {
    setOpenEdit(true);
  };

  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [description, setDescription] = useState("");

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/${openMenuId}`,
      {
        method: "POST",
      }
    );
    const rowData = await result.json();

    navigate(`/clients/${openMenuId}`, { state: rowData });

    // setOpenDialog(true);
  };

  // Function Starts Here

  const [updateName, setUpdateName] = React.useState("");
  const [updateRadio, setUpdateRadio] = React.useState("");

  const [updateDescription, setUpdateDescription] = React.useState("");
  const [updateCategory, setUpdateCategory] = useState(""); // Initialize state for the date
  const [updateType, setUpdateType] = useState("");
  const [userType, setUserType] = useState("");
  const [articalCategory, setArticalCategory] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);
  let valueToSend;

  if (selectedRadio === "Client") {
    valueToSend = 1;
  } else if (selectedRadio === "Employee") {
    valueToSend = 2;
  } else if (selectedRadio === "App User") {
    valueToSend = 3;
  }
  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setUploadFile("");
    setSelectedRadio("");
    setSelectCategory("");
  };
  // Access the empid
  const newid = userInfo?.value?.empid;
  const item = {
    title: name,
    description: description,
    file: uploadFile,
    for: valueToSend,
    user_type: userType,
    article_id: selectCategory,
    creator_id: newid,
  };

  async function SaveData() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/AddKnowledagebase",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    const response = await result.json();

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }

  // ends Here

  // Table Starts Here
  const handleFollowUp = () => {};
  const handleNote = () => {};
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/GetKnowledagebases"
    );
    const response = await results.json();

    // Map through response to convert notice_to values
    // const updatedResponse = response.map((notice) => ({
    //   ...notice,
    //   for: convertToUserType(notice.for),
    //   user_type: convertToMainUserType(notice.user_type), // convert the value
    // }));

    setArticalData(response); // Store the updated response
  }

  const convertToUserType = (value) => {
    if (value === "1") return "Client";
    if (value === "2") return "Employee";
    if (value === "3") return "App User";
    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };
  useEffect(() => {
    getDataCat();
  }, []);

  async function getDataCat() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllKnowledgeArticles"
    );
    const response = await results.json();

    // Map through response to convert notice_to values

    setArticalCategory(response); // Store the updated response
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "title",
      label: "Client ID",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Client ID
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "article",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "for",
      label: "Company Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Company Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "for",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Email
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleFollowUp(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RememberMeIcon /> Follow Up
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleNote(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EventNoteIcon /> Add Note
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </td>
          </>
        ),
      },
    },
  ];
  const columnsNew = [
    {
      name: "title",
      label: "Category Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Category Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", //Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", //Add padding for starting point
              }}
            >
              <Button variant="outlined">
                <div
                  onClick={() => handleDeleteCat(value)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DeleteForeverIcon /> Delete
                </div>
              </Button>
            </td>
          </>
        ),
      },
    },
  ];
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  async function SaveCategory() {
    const formData = new FormData();

    formData.append("title", categoryName);
    formData.append("creator_id", empid);

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addKnowledgeArticles",
      {
        method: "POST",
        body: formData,
      }
    );

    if (result.ok) {
      showAlert("Added successfully", "success");

      setOpenCategory(false);
      getDataCat();
      setCategoryName("");
    }
  }
  async function handleEdit() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/" +
        openMenuId,
      { method: "POST" }
    );
    const resultNew = await result.json();
    const updatedResponse = {
      ...resultNew,
      for: convertToUserType(resultNew.for),
      user_type: convertToMainUserType(resultNew.user_type),
    };
    setData(updatedResponse);
    handleUpdateEdit();
  }

  // Update Values Start

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateName("");
    setUpdateRadio("");
    setUpdateDescription("");
    setUploadFile("");
    setUpdateCategory("");
  };

  useEffect(() => {
    setUpdateName(data.title);
  }, [data.title]);

  useEffect(() => {
    setUpdateRadio(data.for);
  }, [data.for]);

  useEffect(() => {
    setUpdateDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    setUploadFile(data.file);
  }, [data.file]);
  useEffect(() => {
    setUpdateCategory(data.article);
  }, [data.article]);
  useEffect(() => {
    setUpdateCategory(data.user_type);
  }, [data.user_type]);

  useEffect(() => {
    if (updateRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [updateRadio]);
  let valueTouser;
  if (updateRadio === "Client") {
    valueTouser = 1;
  } else if (updateRadio === "Employee") {
    valueTouser = 2;
  } else if (updateRadio === "App User") {
    valueTouser = 3;
  }
  async function UpdateData() {
    const formData = new FormData();

    formData.append("title", updateName);
    formData.append("description", updateDescription);
    formData.append("file", uploadFile);
    formData.append("for", valueTouser);
    formData.append("article_id", updateCategory);
    formData.append("user_type", updateType);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/UpdateKnowledagebase/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert(" updated successfully", "success");

      handleCloseEdit();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete() {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/DeleteKnowledagebase/" +
        openMenuId,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }
  async function handleDeleteCat(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/deleteKnowledgeArticle/" + id,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getDataCat();
    showAlert(" Deleted successfully", "warning");
  }
  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const optionsNew = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 2,
    rowsPerPageOptions: [2, 4, 6, 8, 10, 12, 14, 16],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  const SalutationValue = ["Mr", "Mrs", "Miss", "Dr.", "Sir", "Madam"];

  const GenderValue = ["Male", "Female"];
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <ClientsHeader />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Article
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={11} sm={5} md={5}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={updateRadio}
                  onChange={(e) => setUpdateRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="Employee"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Employees"
                  />
                  <FormControlLabel
                    value="Client"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Clients"
                  />
                  <FormControlLabel
                    value="App User"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82f5" },
                        }}
                      />
                    }
                    label="For App Users"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButton && (
              <Grid item xs={11} sm={5} md={5}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  UserType
                </Typography>
                <Select
                  value={updateType}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateType(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Driver</MenuItem>
                  <MenuItem value={2}>Owner</MenuItem>
                  <MenuItem value={3}>Dhaba</MenuItem>
                  <MenuItem value={4}>Mechanic</MenuItem>
                  <MenuItem value={5}>Crane Owner</MenuItem>
                  <MenuItem value={6}>Crane Operator</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Heading </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Article Heading Name"
                  type="text"
                  fullWidth
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Category</Typography>
                <Select
                  value={updateCategory}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {articalCategory.map((artical) => (
                    <MenuItem value={artical.id}>{artical.title}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px", marginBottom: "10px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <ReactQuill
                value={updateDescription}
                onChange={setUpdateDescription}
                modules={modules}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                }}
              />
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              padding: {
                xs: "60px 10px 20px 20px",
                sm: "40px 10px 20px 20px",
                md: "20px 10px 20px 20px",
              },
            }}
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <br></br>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Upload File
              </Typography>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                  minHeight: "100px", // Set your desired minimum height
                  backgroundColor: "lightgray", // Change background color to light gray
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose a File
              </label>
              <input
                onChange={handleFileChange}
                type="file"
                id="file-upload"
                style={{ display: "none" }}
              />
              {uploadFile && (
                <div style={{ color: "black" }}>{uploadFile.name}</div>
              )}
            </Stack>
          </Grid>

          <Grid container sx={{ padding: "0 0 20px 20px" }}>
            <Grid item sx={11} className="messageFooter">
              <Box className="response" onClick={UpdateData}>
                Update
              </Box>
              <Box className="cancel" onClick={handleCloseEdit}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <>
        <Stack
          direction="row"
          spacing={2}
          sx={{ margin: "10px", marginTop: "20px" }}
        >
          <Box
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",

              height: "40px",
              width: "110px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            Add Client
          </Box>
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Add New Client
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ background: "#fff" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={1.5} md={1}>
                <Stack direction="column" spacing={2}>
                  <Typography>Salutation</Typography>
                  <Select
                    value={selectCategory}
                    autoFocus
                    fullWidth
                    onChange={(e) => setSelectCategory(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    {SalutationValue.map((artical) => (
                      <MenuItem value={artical}>{artical}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={4} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client Name
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    placeholder="e.g.Name"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Email
                  </Typography>

                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    placeholder="eg@example.com"
                    type="email"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={8} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Profile Picture
                  </Typography>

                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "55px", // Set your desired minimum height
                      backgroundColor: "#fff", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={4} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Password
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="e.g.+91 12345689"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={8} sm={4} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client Category
                  </Typography>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <Select
                      labelId="designation-select-label"
                      id="designation-select"
                      value={selectedDesignation}
                      onChange={(e) => setSelectedDesignation(e.target.value)}
                      displayEmpty
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleAddClickDesig}
                            edge="end"
                            sx={{
                              color: "#000",
                              backgroundColor: "#fff",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#000",
                              },
                            }}
                          >
                            Add
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      {designations.map((item) => (
                        <MenuItem key={item.id} value={item.designation}>
                          {item.designation}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* Dialog for adding new department */}
                    <Dialog
                      fullWidth
                      maxWidth="md"
                      open={openDialogDesig}
                      onClose={handleDialogCloseDesig}
                      TransitionComponent={Transition}
                      PaperProps={{
                        style: {
                          position: "absolute",
                          background: "#141b2d",
                          top: "0",
                          backgroundColor: "white",
                          maxHeight: "95%",
                          margin: "auto",
                          overflowX: "hidden",
                          zIndex: 1200,
                        },
                      }}
                    >
                      <Box sx={{ background: "#fff", color: "#000" }}>
                        <DialogTitle>
                          <span
                            style={{
                              background: "blue",
                              color: "#fff",
                              padding: "15px",
                              borderRadius: "5px",
                            }}
                          >
                            Add Client Category
                          </span>
                        </DialogTitle>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            marginTop: "10px",
                            padding: { xs: "10px", sm: "20px" },
                          }}
                        >
                          <Grid item xs={12} sm={6} md={5.5}>
                            <Stack direction="column" spacing={2}>
                              <Typography>Name</Typography>
                              <TextField
                                autoFocus
                                margin="dense"
                                placeholder="Designation Name"
                                type="text"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                inputProps={{ style: { color: "black" } }}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black",
                                    "&:hover fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    "&::placeholder": { color: "gray" },
                                  },
                                }}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                            <DialogActions>
                              <Button
                                onClick={handleDialogCloseDesig}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={name}
                                color="primary"
                                sx={{
                                  background: "blue",
                                  color: "#fff",
                                  "&:hover": {
                                    background: "#000",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </DialogActions>
                          </Grid>
                        </Grid>
                      </Box>
                    </Dialog>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={8} sm={4} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client Sub Category
                  </Typography>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <Select
                      labelId="department-select-label"
                      id="department-select"
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                      displayEmpty
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // onClick={handleAddClick}
                            edge="end"
                            sx={{
                              color: "#000",
                              backgroundColor: "#fff",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#000",
                              },
                            }}
                          >
                            Add
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      {departments.map((department) => (
                        <MenuItem
                          key={department.id}
                          value={department.department}
                        >
                          {department.department}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* Dialog for adding new department */}
                    <Dialog
                      fullWidth
                      maxWidth="md"
                      // open={openDialog}
                      // onClose={handleDialogClose}
                      TransitionComponent={Transition}
                      PaperProps={{
                        style: {
                          position: "absolute",
                          background: "#141b2d",
                          top: "0",
                          backgroundColor: "white",
                          maxHeight: "95%",
                          margin: "auto",
                          overflowX: "hidden",
                          zIndex: 1200,
                        },
                      }}
                    >
                      <Box sx={{ background: "#fff", color: "#000" }}>
                        <DialogTitle>
                          <span
                            style={{
                              background: "blue",
                              color: "#fff",
                              padding: "15px",
                              borderRadius: "5px",
                            }}
                          >
                            Add New Department
                          </span>
                        </DialogTitle>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            padding: { xs: "10px", sm: "20px" },
                          }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <Stack direction="column" spacing={2}>
                              <Typography>Name</Typography>
                              <TextField
                                autoFocus
                                margin="dense"
                                placeholder="Department Name"
                                type="text"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                inputProps={{ style: { color: "black" } }}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black",
                                    "&:hover fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    "&::placeholder": { color: "gray" },
                                  },
                                }}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <Stack direction="column" spacing={2}>
                              <Typography>Parent</Typography>
                              <Select
                                value={name}
                                autoFocus
                                fullWidth
                                onChange={(e) => setName(e.target.value)}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                sx={{
                                  border: "1px solid black",
                                  boxSizing: "border-box",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              >
                                <MenuItem disabled value="">
                                  <em>---</em>
                                </MenuItem>
                                <MenuItem value={1}>Marketing</MenuItem>
                                <MenuItem value={2}>Sales</MenuItem>
                                <MenuItem value={3}>Human Resources</MenuItem>
                                <MenuItem value={4}>Public Relations</MenuItem>
                                <MenuItem value={5}>Research</MenuItem>
                                <MenuItem value={6}>Finance</MenuItem>
                              </Select>
                            </Stack>
                          </Grid>

                          <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                            <DialogActions>
                              <Button
                                // onClick={handleDialogClose}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                // onClick={AddDepartment}
                                color="primary"
                                sx={{
                                  background: "blue",
                                  color: "#fff",
                                  "&:hover": {
                                    background: "#000",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </DialogActions>
                          </Grid>
                        </Grid>
                      </Box>
                    </Dialog>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Mobile No
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="e.g.+91 12345689"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={2.5}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Country
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="e.g.India"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={2.5}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Language
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setLanguage(e.target.value)}
                    placeholder="e.g.hindi, english"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2} md={1.5}>
                <Stack direction="column" spacing={2}>
                  <Typography>Gender</Typography>
                  <Select
                    value={selectCategory}
                    autoFocus
                    fullWidth
                    onChange={(e) => setSelectCategory(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    {GenderValue.map((artical) => (
                      <MenuItem value={artical}>{artical}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={10} sm={10}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Address
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="e.g.No:2468,Street, Nagar, City"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    About
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setAbout(e.target.value)}
                    placeholder="Type here..."
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={name}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Save
            </Button>
          </Box>
        </Dialog>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Clients Lists
                  </span>
                }
                data={articalData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </>
    </>
  );
}

export default Clients;
