import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Box,
  Button,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { tokens } from "../../theme";
import StatusAlert from "../../StatusAlertList/StatusAlert";
import AddIcon from "@mui/icons-material/Add";
import { Grid, TextField, Select } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import MenuItem from "@mui/material/MenuItem";

import { DatePicker } from "@mui/x-date-pickers";

import PaletteIcon from "@mui/icons-material/Palette";
import ReactQuill from "react-quill";
import { ChromePicker } from "react-color";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Events() {
  const [member, setMember] = React.useState("");
  const [leaveType, setLeaveType] = React.useState("");
  const [uploadFile, setUploadFile] = useState([]);
  const [duration, setDuration] = React.useState("");
  const [date, setDate] = useState(null); // Initialize state for the date
  const [memberError, setMemberError] = React.useState(false);
  const [reason, setReason] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [color, setColor] = useState("#ff0000");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const Andrew = "Andrew";
  const Bohr = "Bohr";
  const Ceaser = "Ceaser";
  const Casual = "Casual";
  const Sick = "Sick";
  const Earned = "Earned";

  const Pending = "Pending";
  const Completed = "Completed";
  const Cancelled = "Cancelled";
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name); // Update file name
  };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openColor = Boolean(anchorEl);
  const id = openColor ? "color-picker-popover" : undefined;
  const handleSave = () => {
    const list = { member, leaveType, status, duration, date };

    if (member === "") {
      setMemberError(true);
    } else {
      const list = { member, leaveType, status };

      setMemberError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };

  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = leaveFile.filter((row) => row.id === openMenuId);

    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  const [status, setStatus] = useState("");

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const [openMenuId, setOpenMenuId] = useState(null);

  const item = {
    member,
    memberError,
    reason,
    duration,
    date,
    leaveType,
    fileName,
  };
  const [hide, setHide] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleClickRadio = () => {
    setHide((prev) => !prev);
  };

  const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    fontSize: "1.5rem", // Set the size you want
  });

  const StyledCheckBoxIcon = styled(CheckBoxIcon)({
    fontSize: "1.5rem", // Set the size you want
  });
  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addBusinesslocation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert("Leave Added successfully", "success");

    getData();
    setOpen(false);
  }

  const [leaveFile, setLeaveFile] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/listBusinessLocations"
    );

    const response = await results.json();
    setLeaveFile(response);
  }

  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const colors = tokens(theme.palette.mode);

  // const handleDateClick = (selected) => {
  //   const title = prompt("Please enter a new holiday for you");
  //   const calendarApi = selected.view.calendar;
  //   calendarApi.unselect();

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: `${selected.dateStr}-${title}`,
  //       title,
  //       start: selected.startStr,
  //       end: selected.endStr,
  //       allDay: selected.allDay,
  //     });
  //   }
  // };

  // const handleEventClick = (selected) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete the holiday '${selected.event.title}'`
  //     )
  //   ) {
  //     selected.event.remove();
  //   }
  // };
  const [currentEvents, setCurrentEvents] = useState([]);
  useEffect(
    (events) => {
      // Set events only if needed, to avoid an infinite loop
      if (currentEvents !== events) {
        setCurrentEvents(events);
      }
    },
    [currentEvents]
  );
  // Callback function for when a date is selected
  const handleDateSelect = (selectInfo) => {
    let title = prompt("Please enter a new title for your event:");
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // Clear the selection

    if (title) {
      calendarApi.addEvent({
        id: String(new Date().getTime()), // Unique ID for the event
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  // Callback function for when an event is clicked
  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'?`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  // Callback function to set the current events
  const handleEventsSet = (events) => {
    setCurrentEvents(events);
  };

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <React.Fragment>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginLeft: "14px", textAlign: "center", marginBottom: "5px" }}
        >
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "blue",
              "&:hover": { backgroundColor: "orange" },
            }}
          >
            <AddIcon />
            Add Event
          </Button>
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Add New Event
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "black", fontSize: "20px" }}
              component="div"
            >
              Event Details
            </Typography>
            <hr sx={{ color: "grey" }} />
            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={10}
                  sm={3.5}
                  md={3.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Event Name
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="email"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Label Color
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        value={color}
                        onClick={handleClick}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <PaletteIcon />
                            </IconButton>
                          ),
                        }}
                        style={{ width: "200px", backgroundColor: color }}
                      />
                      <Popover
                        id={id}
                        open={openColor}
                        anchorEl={anchorEl}
                        onClose={handleCloseAnchor}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <ChromePicker
                          color={color}
                          onChangeComplete={handleColorChange}
                        />
                      </Popover>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={3.5}
                  md={3.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Where
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="text"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Description<span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                <ReactQuill
                  value={duration}
                  onChange={() => {}}
                  // modules={modules}
                  // theme="snow"
                  style={{
                    // border: '1px solid black',
                    // borderRadius: '4px',
                    //padding: '10px',
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                    // minHeight: '100px' // Set your desired minimum height
                  }}
                />
                {/* <SuppressWarnings>
                                  <ReactQuill
                                    value={summary}
                                    onChange={handleChange}
                                    style={{
                                      width: '100%',
                                      boxSizing: 'border-box',
                                      color: 'black',
                                      borderBlockColor: 'black'
                                    }}
                                  />
                                </SuppressWarnings> */}
              </Stack>
            </Grid>

            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={11}
                  md={5.5}
                  sm={5.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Select Employee <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={member}
                      autoFocus
                      onChange={(e) => setMember(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Andrew}>Andrew</MenuItem>
                      <MenuItem value={Bohr}>Bohr</MenuItem>
                      <MenuItem value={Ceaser}>Ceaser</MenuItem>
                    </Select>
                    {memberError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select a member
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={5.5}
                  sm={5.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Select Client
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Casual}>Casual</MenuItem>
                      <MenuItem value={Sick}>Sick</MenuItem>
                      <MenuItem value={Earned}>Earned</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={11}
                  md={5.5}
                  sm={5.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Host <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={member}
                      autoFocus
                      onChange={(e) => setMember(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Andrew}>Andrew</MenuItem>
                      <MenuItem value={Bohr}>Bohr</MenuItem>
                      <MenuItem value={Ceaser}>Ceaser</MenuItem>
                    </Select>
                    {memberError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select a member
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={5.5}
                  sm={5.5}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Status
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Pending}>Pending</MenuItem>
                      <MenuItem value={Completed}>Completed</MenuItem>
                      <MenuItem value={Cancelled}>Cancelled</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <br></br>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Add File
                </Typography>
                <label
                  htmlFor="file-upload"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "4px",
                    padding: "10px",
                    width: "100%",
                    boxSizing: "border-box",
                    minHeight: "100px", // Set your desired minimum height
                    backgroundColor: "lightgray", // Change background color to light gray
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Choose a File
                </label>
                <input
                  onChange={uploadFile}
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                />
                {uploadFile && (
                  <div style={{ color: "black" }}>{uploadFile}</div>
                )}
              </Stack>
            </Grid>
            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={handleSave}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Save
            </Button>
          </Box>
        </Dialog>
      </React.Fragment>
      <Box flex="1 1 100%" ml="15px" sx={{ background: "#fff", color: "#000" }}>
        <FullCalendar
          height="75vh"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          select={handleDateSelect} // Use the defined function
          eventClick={handleEventClick} // Use the defined function
          eventsSet={handleEventsSet} // Use the defined function
          events={currentEvents} // Provide the events state
        />
      </Box>
    </>
  );
}

export default Events;
