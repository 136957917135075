import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CompanySettings.css";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { companySettingsApi } from "../../../Data/axios"; 

function CompanySettings({ isDown }) {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const [companyDetails, setCompanyDetails] = useState({
    site_title: "",
    site_address: "",
    site_email: "",
    site_phone: "",
  });

  const handleChange = (e) =>
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });

  // Fetch company details on load
  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    try {
      const data = await companySettingsApi.getAll();
      if (data.length) {
        setCompanyDetails({
          site_title: data[0].site_title || "",
          site_address: data[0].site_address || "",
          site_email: data[0].site_email || "",
          site_phone: data[0].site_phone || "",
        });
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
      showAlert("Failed to fetch Company Data!", "error");
    }
  };

  const updateCompanyData = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await companySettingsApi.post(companyDetails);
      setLoading(false);
      showAlert("Company Data Updated Successfully!", "success");
    } catch (error) {
      setLoading(false);
      console.error("Error updating company data:", error);
      showAlert("Failed to update the Company Data!", "error");
    }
  };

  return (
    <Box component="div">
      <div className="companysettings_container">
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />

        <h1>Company Settings</h1>
        <hr />
        <form onSubmit={updateCompanyData}>
          <div className="row">
            <div className="column">
              <label htmlFor="name">Company Name</label>
              <input
                type="text"
                id="name"
                name="site_title"
                placeholder="Road Pilot Technologies PVT LTD"
                value={companyDetails.site_title}
                onChange={handleChange}
              />
            </div>
            <div className="column">
              <label htmlFor="email">Company Email</label>
              <input
                type="email"
                id="email"
                name="site_email"
                value={companyDetails.site_email}
                placeholder="contact@info.roadpilot.co.in"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="phone">Company Phone Number</label>
              <input
                type="text"
                id="phone"
                name="site_phone"
                placeholder="+91 1234567890"
                value={companyDetails.site_phone}
                onChange={handleChange}
              />
            </div>
            <div className="column">
              <label htmlFor="address">Company Address</label>
              <input
                type="text"
                id="address"
                name="site_address"
                placeholder="Lucknow UP 226002 INDIA"
                value={companyDetails.site_address}
                onChange={handleChange}
              />
            </div>
          </div>

          <button type="submit" className="companyButton">
            {!loading ? "Submit" : "Updating..."}
          </button>
        </form>
      </div>
    </Box>
  );
}

export default CompanySettings;
