import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";

import PaletteIcon from "@mui/icons-material/Palette";

import EventNoteIcon from "@mui/icons-material/EventNote";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";

import StatusAlert from "../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
import MembershipHeader from "./MembershipHeader";
import { ChromePicker } from "react-color";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Membership() {
  const [name, setName] = React.useState("");
  const [radioButton, setRadioButton] = useState(false);
  const [color, setColor] = useState("#ff0000");
  const [uploadFile, setUploadFile] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [empid, setEmpid] = React.useState(false);

  const [price, setPrice] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [call, setCall] = React.useState("");

  const [post, setPost] = React.useState("");

  const [validity, setValidity] = React.useState("");

  const [userType, setUserType] = useState("");
  const [visibility, setVisibility] = useState("");

  const [articalData, setArticalData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Client");
  const [data, setData] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/changestatus/${ticketId}`,
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("updated successfully", "success");

    getData();
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateEdit = () => {
    setOpenEdit(true);
  };

  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [description, setDescription] = useState("");

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/${openMenuId}`,
      {
        method: "POST",
      }
    );
    const rowData = await result.json();

    navigate(`/clients/${openMenuId}`, { state: rowData });

    // setOpenDialog(true);
  };

  // Function Starts Here

  const [updateName, setUpdateName] = React.useState("");
  const [updateRadio, setUpdateRadio] = React.useState("");

  const [updateDescription, setUpdateDescription] = React.useState("");
  const [updateCategory, setUpdateCategory] = useState(""); // Initialize state for the date
  const [updateType, setUpdateType] = useState("");

  const [articalCategory, setArticalCategory] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setUploadFile("");
    setSelectedRadio("");
    setSelectCategory("");
  };
  // Access the empid
  const newid = userInfo?.value?.empid;
  const item = {
    membership: name,
    price: price,
    discount: discount,
    calls: call,
    jobspost: post,
    validity: validity,
    role: userType,
    color: color,
    access: visibility,
  };

  async function SaveData() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addMemberShip",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    const response = await result.json();

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }

  // ends Here

  // Table Starts Here

  const handleNote = () => {};
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getMemberShips"
    );
    const response = await results.json();

    // Map through response to convert notice_to values
    const updatedResponse = response.map((data) => ({
      ...data,
      access: convertToUserType(data.access),
      role: convertToMainUserType(data.role),
    }));

    setArticalData(updatedResponse); // Store the updated response
  }

  const convertToUserType = (value) => {
    if (value === "1") return "Location";
    if (value === "2") return "Nation Wide";

    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };
  useEffect(() => {
    getDataCat();
  }, []);

  async function getDataCat() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllKnowledgeArticles"
    );
    const response = await results.json();

    // Map through response to convert notice_to values

    setArticalCategory(response); // Store the updated response
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleClickColor = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const columns = [
    {
      name: "membership",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Price
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Role
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Open
                      </MenuItem>
                    ); // Placeholder text
                  }

                  if (selected === "0") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Closed
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Open
                </MenuItem>
                <MenuItem
                  value={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Closed
                </MenuItem>
              </Select>
            </td>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleNote(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EventNoteIcon /> Add Details
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </td>
          </>
        ),
      },
    },
  ];
  const columnsNew = [
    {
      name: "title",
      label: "Category Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Category Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </td>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", //Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", //Add padding for starting point
              }}
            >
              <Button variant="outlined">
                <div
                  onClick={() => handleDeleteCat(value)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DeleteForeverIcon /> Delete
                </div>
              </Button>
            </td>
          </>
        ),
      },
    },
  ];

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  async function SaveCategory() {
    const formData = new FormData();

    formData.append("title", categoryName);
    formData.append("creator_id", empid);

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addKnowledgeArticles",
      {
        method: "POST",
        body: formData,
      }
    );

    if (result.ok) {
      showAlert("Added successfully", "success");

      setOpenCategory(false);
      getDataCat();
      setCategoryName("");
    }
  }
  async function handleEdit() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/" +
        openMenuId,
      { method: "POST" }
    );
    const resultNew = await result.json();
    const updatedResponse = {
      ...resultNew,
      for: convertToUserType(resultNew.for),
      user_type: convertToMainUserType(resultNew.user_type),
    };
    setData(updatedResponse);
    handleUpdateEdit();
  }

  // Update Values Start

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateName("");
    setUpdateRadio("");
    setUpdateDescription("");
    setUploadFile("");
    setUpdateCategory("");
  };

  useEffect(() => {
    setUpdateName(data.title);
  }, [data.title]);

  useEffect(() => {
    setUpdateRadio(data.for);
  }, [data.for]);

  useEffect(() => {
    setUpdateDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    setUploadFile(data.file);
  }, [data.file]);
  useEffect(() => {
    setUpdateCategory(data.article);
  }, [data.article]);
  useEffect(() => {
    setUpdateCategory(data.user_type);
  }, [data.user_type]);

  useEffect(() => {
    if (updateRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [updateRadio]);
  let valueTouser;
  if (updateRadio === "Client") {
    valueTouser = 1;
  } else if (updateRadio === "Employee") {
    valueTouser = 2;
  } else if (updateRadio === "App User") {
    valueTouser = 3;
  }
  async function UpdateData() {
    const formData = new FormData();

    formData.append("title", updateName);
    formData.append("description", updateDescription);
    formData.append("file", uploadFile);
    formData.append("for", valueTouser);
    formData.append("article_id", updateCategory);
    formData.append("user_type", updateType);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/UpdateKnowledagebase/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert(" updated successfully", "success");

      handleCloseEdit();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete() {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/DeleteKnowledagebase/" +
        openMenuId,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }
  async function handleDeleteCat(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/deleteKnowledgeArticle/" + id,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getDataCat();
    showAlert(" Deleted successfully", "warning");
  }
  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const optionsNew = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 2,
    rowsPerPageOptions: [2, 4, 6, 8, 10, 12, 14, 16],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here
  const membershipValue = ["Classified", "Bucket", "Premium"];

  const openColor = Boolean(anchorEl1);
  const id = openColor ? "color-picker-popover" : undefined;
  const handleCloseAnchor = () => {
    setAnchorEl1(null);
  };
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <MembershipHeader />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Article
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={11} sm={5} md={5}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={updateRadio}
                  onChange={(e) => setUpdateRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="Employee"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Employees"
                  />
                  <FormControlLabel
                    value="Client"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Clients"
                  />
                  <FormControlLabel
                    value="App User"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82f5" },
                        }}
                      />
                    }
                    label="For App Users"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButton && (
              <Grid item xs={11} sm={5} md={5}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  UserType
                </Typography>
                <Select
                  value={updateType}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateType(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Driver</MenuItem>
                  <MenuItem value={2}>Owner</MenuItem>
                  <MenuItem value={3}>Dhaba</MenuItem>
                  <MenuItem value={4}>Mechanic</MenuItem>
                  <MenuItem value={5}>Crane Owner</MenuItem>
                  <MenuItem value={6}>Crane Operator</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Heading </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Article Heading Name"
                  type="text"
                  fullWidth
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Category</Typography>
                <Select
                  value={updateCategory}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {articalCategory.map((artical) => (
                    <MenuItem value={artical.id}>{artical.title}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px", marginBottom: "10px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <ReactQuill
                value={updateDescription}
                onChange={setUpdateDescription}
                modules={modules}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                }}
              />
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              padding: {
                xs: "60px 10px 20px 20px",
                sm: "40px 10px 20px 20px",
                md: "20px 10px 20px 20px",
              },
            }}
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <br></br>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Upload File
              </Typography>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                  minHeight: "100px", // Set your desired minimum height
                  backgroundColor: "lightgray", // Change background color to light gray
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose a File
              </label>
              <input
                onChange={handleFileChange}
                type="file"
                id="file-upload"
                style={{ display: "none" }}
              />
              {uploadFile && (
                <div style={{ color: "black" }}>{uploadFile.name}</div>
              )}
            </Stack>
          </Grid>

          <Grid container sx={{ padding: "0 0 20px 20px" }}>
            <Grid item sx={11} className="messageFooter">
              <Box className="response" onClick={UpdateData}>
                Update
              </Box>
              <Box className="cancel" onClick={handleCloseEdit}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <>
        <Stack
          direction="row"
          spacing={2}
          sx={{ margin: "10px", marginTop: "20px" }}
        >
          <Box
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",

              height: "40px",
              width: "160px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            Add Membership
          </Box>
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Add New Membership
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ background: "#fff" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={4} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography>Membership</Typography>
                  <Select
                    value={name}
                    autoFocus
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    {membershipValue.map((artical) => (
                      <MenuItem value={artical}>{artical}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={4} sm={3} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Price
                  </Typography>

                  <TextField
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="e.g.2000"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Discount
                  </Typography>

                  <TextField
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    fullWidth
                    placeholder="eg.500"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Calls
                  </Typography>

                  <TextField
                    fullWidth
                    value={call}
                    onChange={(e) => setCall(e.target.value)}
                    placeholder="e.g.50"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={3} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Job Posts
                  </Typography>

                  <TextField
                    fullWidth
                    value={post}
                    onChange={(e) => setPost(e.target.value)}
                    placeholder="e.g.50"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={3} md={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Validity
                  </Typography>

                  <TextField
                    fullWidth
                    value={validity}
                    onChange={(e) => setValidity(e.target.value)}
                    placeholder="e.g.50"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={4} md={4}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  User Type
                </Typography>
                <Select
                  value={userType}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUserType(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Driver</MenuItem>
                  <MenuItem value={2}>Owner</MenuItem>
                  <MenuItem value={3}>Dhaba</MenuItem>
                  <MenuItem value={4}>Mechanic</MenuItem>
                  <MenuItem value={5}>Crane Owner</MenuItem>
                  <MenuItem value={6}>Crane Operator</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={11} sm={3} md={3}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  Access Visibility
                </Typography>
                <Select
                  value={visibility}
                  autoFocus
                  fullWidth
                  onChange={(e) => setVisibility(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Location</MenuItem>
                  <MenuItem value={2}>Nation Wide</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={11} sm={3} md={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Color Picker
                </Typography>

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ paddingTop: "7px" }}
                >
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      value={color}
                      onClick={handleClickColor}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <PaletteIcon />
                          </IconButton>
                        ),
                      }}
                      style={{ width: "200px", backgroundColor: color }}
                    />
                    <Popover
                      id={id}
                      open={openColor}
                      anchorEl={anchorEl1}
                      onClose={handleCloseAnchor}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <ChromePicker
                        color={color}
                        onChangeComplete={handleColorChange}
                      />
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                padding: "10px 0 30px 15px",
              }}
            >
              <Grid item xs={11} className="messageFooter">
                <Box className="response" onClick={SaveData}>
                  Save
                </Box>
                <Box className="cancel" onClick={handleClose}>
                  Cancel
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Dialog>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Membership Lists
                  </span>
                }
                data={articalData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </>
    </>
  );
}

export default Membership;
