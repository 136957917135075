import {
  createTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  Stack,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Slide from "@mui/material/Slide";

import MUIDataTable from "mui-datatables";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { categoryType } from "../../../utils/customers";
import { useLocalStorageContext } from "../../../Context/LocalProvider";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { Select } from "@mui/material";

import config from "../../../Data/AuthUser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DriverClients() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const { data: userData } = useLocalStorageContext();
  const navigate = useNavigate();

  const userDataNew = localStorage.getItem("user-info");
  let creator;
  if (userDataNew) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userDataNew);

    // Access the name property from the parsed object
    creator = parsedData.value.empid;
  } else {
    console.error("No user data found in localStorage.");
  }
  let valueToSend;

  if (selectedRadio === "male") {
    valueToSend = 1;
  } else if (selectedRadio === "female") {
    valueToSend = 2;
  } else if (selectedRadio === "others") {
    valueToSend = 3;
  }

  const item = {
    username: name,
    email: email,
    phone: mobile,
    role: "1",
    address: address,
    state: state,
    pincode: pinCode,
    ref: creator,
    gender: valueToSend,
  };

  async function SaveData() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/adduserfronportal",
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("Added successfully", "success");

    getData();
    setOpen(false);
  }

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/changestatus/${ticketId}`,
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("updated successfully", "success");

    getData();
  };
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  const handleViewMore = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/getDriverDataForPortal/${openMenuId}`,
      {
        method: "POST",
      }
    );
    const rowData = await result.json();

    navigate(`/Users/Drivers/${openMenuId}`, { state: rowData });
  };
  const handleSaleNote = () => {
    const rowData = leadFiles.filter((row) => row.id === openMenuId);

    navigate(`/leads/sales/${openMenuId}`, { state: rowData[0] });
  };

  // follow up

  const [openMenuId, setOpenMenuId] = useState(null);

  // Table Starts Here

  const [leadFiles, setLeadFiles] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const convertToUserType = (value) => {
    if (value === "1") return "Enabled";
    if (value === "2") return "Disabled";
    return "Unknown"; // Fallback for invalid values
  };
  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getDrivers"
    );

    const response = await results.json();

    setLeadFiles(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //adding follow up

  const columns = [
    {
      name: "username",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          const fullRecord = leadFiles.find(
            (item) => item.user_id === ticketId
          );

          if (!fullRecord) {
            return <td>Loading...</td>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.image}`;

          return (
            <td
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>{value || "Unknown"}</div>
                <div>{ticketId}</div>
              </div>
            </td>
          );
        },
      },
    },

    {
      name: "phone",
      label: "Phone No.",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone No.
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "phone",
      label: "Relieve",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Relieve
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "Status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Active
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Relieve
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "3") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Leave
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Relieve
                </MenuItem>
                <MenuItem
                  value={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Leave
                </MenuItem>
              </Select>
            </td>
          );
        },
      },
    },

    {
      name: "user_id",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <>
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore()}
                    className="MenuItemValue"
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </>
        ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    // rowsPerPage: 5,
    // rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {},
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginBottom: "5px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "140px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                marginTop: "20px",
              }}
            >
              <AddIcon />
              Add Driver
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        sx={{
                          height: "40px",
                          color: "#000",
                          padding: 0,
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "#000",
                          },
                        }}
                      >
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Start typing to search"
                  sx={{
                    width: "260px",
                    height: "40px",

                    border: ".1px solid gray",
                    borderRadius: "5px",
                    color: "#000 ",
                    bgcolor: "#eff1f1",

                    boxSizing: "border-box",
                  }}
                  value={searchValue} // Bind the input value
                  onChange={handleInputChange} // Handle the input change
                />
              </FormControl>
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Driver
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Name
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="e.g.Ramu, Raju"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Personal Email
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        placeholder="eg@example.com"
                        type="email"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5} md={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Gender
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={selectedRadio}
                          onChange={(e) => setSelectedRadio(e.target.value)}
                        >
                          <FormControlLabel
                            value="male"
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": { color: "#1d82fs" },
                                }}
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": { color: "#1d82fs" },
                                }}
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            value="others"
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": { color: "#1d82f5" },
                                }}
                              />
                            }
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Mobile No
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="e.g.+91 1234567890"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    State
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="e.g.India"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Pin Code
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                        placeholder="e.g.hindi, english"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Address
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="e.g.No:2468,Street, Nagar, City"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "10px 0 20px 38px" }}>
                <Grid item sx={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleClose}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              fullScreen={true}
              title={"Driver Lists"}
              data={leadFiles}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default DriverClients;
