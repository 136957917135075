const USER_INFO_KEY = 'user-info';

export const saveUserInfo = (user, expiry) => {
    const data = {
        value: user,
        expiry: expiry
    };
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(data));
};

export const getUserInfo = () => {
    const data = localStorage.getItem(USER_INFO_KEY);
    if (!data) {
        return null;
    }

    const parsedData = JSON.parse(data);
    const currentTime = new Date().getTime();

    if (parsedData.expiry && currentTime > parsedData.expiry) {
        clearUserInfo();
        return null;
    }

    return parsedData.value || null;
};


export const getUserInfoProperty = (key) => {
    const user = getUserInfo();
    return user ? user[key] : undefined;
};


export const clearUserInfo = () => {
    localStorage.removeItem(USER_INFO_KEY);
};

