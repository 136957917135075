import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  CssBaseline,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import MUIDataTable from "mui-datatables";
import EventNoteIcon from "@mui/icons-material/EventNote";
import StatusAlert from "../../StatusAlertList/StatusAlert";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Leads.css";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";

import LeadDialog from "./LeadDialog";

import { useNavigate } from "react-router-dom";
import { categoryType } from "../../utils/customers";
import { useLocalStorageContext } from "../../Context/LocalProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Leads() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const { data: userData } = useLocalStorageContext();
  const navigate = useNavigate();
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [update, setUpdate] = React.useState(false);
  const [followup, setFollowup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };
  const handleClickFollowup = () => {
    setFollowup(true);
  };
  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleUpdateCloseFollow = () => {
    setFollowup(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = (id) => {
    const rowData = leadFiles.filter((row) => row.id === openMenuId);

    navigate(`/leads/${openMenuId}`, { state: rowData[0] });
  };
  const handleConverted = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/convertlead/${openMenuId}`,
      {
        method: "POST",
      }
    );

    getData();
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  // Function Starts Here
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [role, setRole] = React.useState([]);
  const [lead_agent, setLead_agent] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [leas_source, setLeas_source] = React.useState("");

  const [updaterole, setUpdaterole] = useState("");
  const [updatelanguage, setUpdatelanguage] = useState("");
  const [updateaddress, setUpdateaddress] = useState("");
  const [updatelead_agent, setUpdatelead_agent] = useState("");
  const [updatename, setUpdatename] = useState("");
  const [updatephone, setUpdatephone] = useState("");
  const [updateemail, setUpdateemail] = useState("");
  const [updateleas_source, setUpdateleas_source] = useState("");

  const [data, setData] = useState([]);
  // follow up
  const [follow_agent, setFollow_agent] = useState("");
  const [follow_date, setFollow_date] = useState("");

  const [openMenuId, setOpenMenuId] = useState(null);
  const handleGenderChange = (event) => {
    setRole(event.target.value);
  };

  const handleGenderChangeupdate = (event) => {
    setUpdaterole(event.target.value);
  };

  const categoryKeys = Object.keys(categoryType);
  const item = {
    language,
    address,
    lead_agent,
    leas_source,
    phone,
    email,
    name,
    role,
  };

  async function Savefollowup() {
    const formData = new FormData();
    const time = new Date();
    formData.append("follow_agent", follow_agent);
    formData.append("follow_date", follow_date);
    formData.append("assigned_by", userData.empid);
    formData.append("assigned_at", time);

    try {
      let response = await fetch(
        ` https://rptpl.roadpilot.co.in/public/api/assignfollowup/${openMenuId}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert(" Followup updated successfully", "success");

      handleUpdateCloseFollow();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update leads", "error");
    }
  }
  async function SaveDate() {
    try {
      const formData = new FormData();

      // Append each item property to the FormData object
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          formData.append(key, item[key]);
        }
      }

      let result = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/addLeads",
        {
          method: "POST",
          body: formData,
        }
      );

      const response = await result.json();

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      // Assuming the API returns a success property
      showAlert("Leads Added successfully", "success");
      // getData();
      const formObject = {};
      for (const [key, value] of formData.entries()) {
        formObject[key] = value;
      }
      setLeadFiles([formObject, ...leadFiles]);
      setOpen(false);
    } catch (error) {
      console.error("Error:", error); // Log any errors that occur
      showAlert("An error occurred", "error");
    }
  }

  // ends Here

  // Table Starts Here

  const [leadFiles, setLeadFiles] = useState([]);
  const [optionsAgent, setOptionsAgent] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getallLeads"
    );

    const response = await results.json();

    const newResponse = response.filter((item) => item.status !== "0");
    setLeadFiles(newResponse);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //adding follow up
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getUserData"
      ); // Replace with your API endpoint
      const data = await response.json();
      setOptionsAgent(data.map((item) => item.name)); // Adjust to match your data structure
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleFollowup = () => {
    handleClickFollowup();
  };
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "phone",
      label: "Phone No.",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "role",
      label: "Category",
      options: {
        customBodyRender: (value) => {
          return categoryType[value];
        },
      },
    },
    {
      name: "followedby",
      label: "Followed By",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "language",
      label: "Language",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <>
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(value)}
                    className="MenuItemValue"
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleEdit(value)}
                    className="MenuItemValue"
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <EditNoteIcon /> Update
                  </div>
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => {}}
                    className="MenuItemValue"
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <EventNoteIcon /> Note
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} className="MenuItemValue">
                  <div
                    onClick={() => handleFollowup(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    Follow up
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} className="MenuItemValue">
                  <div
                    onClick={() => handleConverted()}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    Converted
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    // let result = await fetch(
    //   "https://rptpl.roadpilot.co.in/public/api/updateLeads/" + id
    // );
    // result = await result.json();
    const result = leadFiles.filter((lead) => lead.id === openMenuId);

    setData(result[0]);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdatename(data.name);
  }, [data.name]);

  useEffect(() => {
    setUpdatephone(data.phone);
  }, [data.phone]);

  useEffect(() => {
    setUpdateemail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdateaddress(data.address);
  }, [data.address]);

  useEffect(() => {
    setUpdatelead_agent(data.lead_agent);
  }, [data.lead_agent]);

  useEffect(() => {
    setUpdateleas_source(data.leas_source);
  }, [data.leas_source]);

  useEffect(() => {
    setUpdatelanguage(data.language);
  }, [data.language]);

  useEffect(() => {
    setUpdaterole(data.role);
  }, [data.role]);

  // Update Values Ends
  // Editing Starts here

  async function UpdateData() {
    const formData = new FormData();

    formData.append("name", updatename);
    formData.append("email", updateemail);
    formData.append("language", updatelanguage);
    formData.append("address", updateaddress);
    formData.append("lead_agent", updatelead_agent);
    formData.append("leas_source", updateleas_source);
    formData.append("role", updaterole);
    formData.append("phone", updatephone);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateLeads/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leads updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update leads", "error");
    }
  }
  const theme = createTheme({
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
    },
  });
  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(" " + id, {
      method: "DELETE",
    });
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    // rowsPerPage: 5,
    // rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <LeadDialog
        open={selectedRow.open}
        handleClose={handleDailogClosing}
        rowData={selectedRow}
      />
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box sx={{ zIndex: 11000 }}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Lead
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ backgroundColor: "white" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Name
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="ex. abc"
                      value={updatename}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatename(newValue);
                      }}
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Email
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Email"
                        value={updateemail}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setUpdateemail(newValue);
                        }}
                        inputProps={{
                          style: {
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          },
                        }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Phone No.
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="0000000000"
                      type="number"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatephone}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatephone(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Language
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Hindi..."
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatelanguage}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatelanguage(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Lead Agent
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Lead agents..."
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      value={updatelead_agent}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatelead_agent(newValue);
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Lead Source
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="lead source"
                      type="text"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateleas_source}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateleas_source(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={5}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Address
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="e.g.Address"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateaddress}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateaddress(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Category
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      required
                      select
                      value={updaterole}
                      onChange={handleGenderChangeupdate}
                      fullWidth
                      style={{
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                      placeholder="Own"
                      inputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiSelect-root": {
                          color: "black", // Set text color to black for the selected option
                        },
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black", // Set border color to black
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                          color: "black", // Set text color to black
                        },
                      }}
                    >
                      {categoryKeys.map((option) => (
                        <MenuItem key={option} value={option}>
                          {categoryType[option]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      {/* Follow up box  */}
      <Box sx={{ zIndex: 11000 }}>
        <Dialog
          open={followup}
          fullWidth
          maxWidth="md"
          onClose={handleUpdateCloseFollow}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateCloseFollow}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Follow Up
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ backgroundColor: "white" }}>
            <Grid
              container
              spacing={2}
              style={{
                color: "black",
                margin: "10px",
              }}
            >
              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Agent
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Autocomplete
                      options={optionsAgent}
                      sx={{ border: "1px solid gray", borderRadius: "5px" }}
                      value={follow_agent}
                      onChange={(event, newValue) => {
                        setFollow_agent(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="ex. abc"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Date
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          PaperProps={{
                            sx: {
                              "& .MuiPickersCalendarHeader-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                              "& .MuiPickersDay-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={Savefollowup}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <ListItemText
          style={{ background: "none", margin: 5, zIndex: 1000000 }}
        >
          <React.Fragment>
            <StatusAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              severity={alertSeverity}
            />
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
              }}
            >
              <AddCircleIcon /> Add New Lead
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    Add Lead
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  backgroundColor: "white",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Name
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          placeholder="name"
                          onChange={(e) => setName(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Email
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="email.."
                          onChange={(e) => setEmail(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Phone No.
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="+91-0000000000"
                          type="number"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setPhone(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Language
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="Hindi..."
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setLanguage(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Lead Agent
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="lead agent"
                          type="text"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(e) => setLead_agent(e.target.value)}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Lead Source
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="lead source"
                          type="text"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setLeas_source(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Address
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="e.g.Address"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setAddress(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Category
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          select
                          value={role}
                          onChange={handleGenderChange}
                          fullWidth
                          style={{
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }}
                          placeholder="Own"
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiSelect-root": {
                              color: "black", // Set text color to black for the selected option
                            },
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black", // Set border color to black
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                              color: "black", // Set text color to black
                            },
                          }}
                        >
                          {categoryKeys.map((option) => (
                            <MenuItem key={option} value={option}>
                              {categoryType[option]}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  autoFocus
                  color="inherit"
                  onClick={SaveDate}
                  sx={{
                    backgroundColor: "blue",
                    "&:hover": { backgroundColor: "orange" },
                    textAlign: "center",
                    margin: "25px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              fullScreen={true}
              title={"Leads"}
              data={leadFiles}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Leads;
